<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                <div class="logo-img"><img class="logo-img" alt="logo" [src]="logoUrl"></div>
                <span
                  class="text"></span>
              </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav" i18n>
          <li class="i18n-dropdown dropdown nav-item mr-2">
            <a class="btn btn-primary helpline-btn" target="_blank"
               href="https://turntech.atlassian.net/servicedesk/customer/portal/6">
              <i class="ft-headphones mr-1"></i>Support</a>
          </li>
          <li class="i18n-dropdown dropdown nav-item mr-2" *ngIf="this.userData?.auth?.data?.userType === 'Staff' && checkInBtnShow">
            <button class="btn checkout" (click)="checkIn_out()"
                    *ngIf="this.organizationList[0]?.organizationId?._id === this.getCheckedOrganization"
                    [ngClass]="this.checkType === 'Check-In' ? 'checkIn' : 'checkout'">{{ this.checkType | translate }}
              <i
                class="ft-clock ml-1"></i></button>
          </li>
          <li *ngIf="this.userData?.auth?.data?.role === 'Super Admin'" class="i18n-dropdown dropdown nav-item mr-2"
              display="dynamic" ngbDropdown>
            <!--Organization Dropdown list-->
            <select class="form-control square"
                    [(ngModel)]="defaultSelectedOrg"
                    (change)="OnChangeOrgGrp($event)" style="width: 250px">
              <option *ngFor="let orgGrp of this.orgGrpList" [value]="orgGrp._id">{{ orgGrp.Name }}</option>
            </select>
          </li>
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <!--Organization Dropdown list-->
            <select
              *ngIf="this.userData?.auth?.data?.role !== 'Super Admin' && this.userData?.auth?.data?.userType !== 'Pharmacy Admin'"
              [(ngModel)]="userData.SelectedOrg"
              class="form-control square" (change)="OnChangeOrg($event)" style="width: 250px">
              <option selected disabled>Select Organization</option>
              <option *ngFor="let org of organizationList"
                      [value]="org.organizationId._id">{{ org.organizationId.name }}
              </option>
            </select>

            <select
              *ngIf="this.userData?.auth?.data?.userType === 'Pharmacy Admin'" (change)="SwitchOrg($event)"
              [(ngModel)]="userData.SelectedOrg"
              class="form-control square" style="width: 250px">
              <option selected disabled>Select Organization</option>
              <option *ngFor="let org of organizationList"
                      [value]="org.organizationId._id">{{ org.organizationId.name }}
              </option>
            </select>


            <select *ngIf="this.userData?.auth?.data?.role === 'Super Admin'" class="form-control square"
                    (change)="OnChangeOrganization_Box($event)" style="width: 250px"
                    [(ngModel)]="userData.SelectedOrg">
              <option selected disabled>Select Organization</option>
              <option *ngFor="let org of organizationList"
                      [value]="org._id">{{ org.name }}
              </option>
            </select>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{ this.user?.name }}</span><span class="text-right text-muted font-small-3">Available</span>
              </div>
              <img
                   class="rounded-circle mr-1 profile-pic" loading="lazy"
                   src="{{this.user?.avatar === null ? 'assets/img/defaultProfile.jpg'
                    : this.user?.avatar}}"
                   alt="avatar" height="35" width="35"/>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
                 ngbDropdownMenu>
              <a class="dropdown-item" (click)="open(myprofile,'md')">
                <div class="d-flex align-items-center">
                  <i class="ft-user mr-2"></i><span>{{ 'My Profile' | translate }} </span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="open(content,'md')">
                <div class="d-flex align-items-center">
                  <i class="ft-lock mr-2"></i><span>Change Password</span>
                </div>
              </a>

              <div class="dropdown-divider" *ngIf="this.userData?.auth?.data.userType === 'Doctor'"></div>
              <a *ngIf="this.userData?.auth?.data.userType === 'Doctor'" class="dropdown-item"
                 (click)="openModal(AutoLogout);">
                <div class="d-flex align-items-center">
                  <i class="ft-settings mr-2"></i><span>Auto Logout Settings</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="this.logout();">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
    <span class="ft ft-x cursor-pointer" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <form [formGroup]="updateForm" (ngSubmit)="OnSubmit(content)">
      <div class="form-group row">
        <label class="col-md-5 col-form-label" for="horizontal-form-11">New Password</label>
        <div class="col-md-12 col-12">
          <div class="input-group">
            <input type="{{toggleEye.newPassword ? 'text' : 'password'}}" class="form-control square"
                   id="horizontal-form-11"
                   formControlName="password">
            <div class="input-group-prepend cursor-pointer">
              <span class="input-group-text cursor-pointer" (click)="toggleEye.newPassword = !toggleEye.newPassword">
                <i class="ft" [ngClass]="toggleEye.newPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
            </div>
          </div>
          <p class="text-danger"
             *ngIf="OnformSubmitted && updateForm.controls['password'].hasError('required')">
            New password is required!</p>
          <p class="text-danger" *ngIf="updateForm.controls['password'].hasError('pattern')">
            Password must be at least eight characters long with a letter, number, and special character.
          </p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-5 col-form-label">Confirm Password</label>
        <div class="col-md-12 col-12">
          <div class="input-group">
            <input type="{{toggleEye.confirmPassword ? 'text' : 'password'}}" class="form-control square"
                   formControlName="confrimPassword">
            <div class="input-group-prepend cursor-pointer">
              <span class="input-group-text " (click)="toggleEye.confirmPassword = !toggleEye.confirmPassword">
                <i class="ft" [ngClass]="toggleEye.confirmPassword ? 'ft-eye' : 'ft-eye-off'"></i></span>
            </div>
          </div>
          <p class="text-danger" *ngIf="OnformSubmitted && updateForm.controls['confrimPassword'].hasError('required')">
            Confirm password is required!</p>
          <p class="text-danger" *ngIf="updateForm.controls['confrimPassword'].hasError('pattern')">
            Password must be at least eight characters long with a letter, number, and special character.
          </p>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary mr-2"><i class="ft-lock mr-1"></i>Change Password</button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #myprofile let-modal>
  <div class="modal-header">
    <h4 class="modal-title">My Profile</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="media">
          <img
            [src]="profileImage.toString()"
            alt="user edit avatar"
            class="users-avatar-shadow rounded-circle mb-3 fit-image" height="64" width="64">
          <div class="media-body ml-2">
            <h5>Profile Picture</h5>
            <div class="d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2">
              <label class="btn btn-sm btn-primary text-white" for="uploadDoctorPic">Change</label>
              <input type="file" (change)="upload($event)" id="uploadDoctorPic"
                     class="form-control d-none"
                     accept="image/png,image/jpg,image/jpeg">
            </div>
            <span class="px-1 py-1">{{ file?.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="editForm" (ngSubmit)="EditProfileDetails(modal)">
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="form-group mb-2">
                <label for="horizontal-form-1">Name</label>
                <input type="text" class="form-control square" formControlName="name" id="horizontal-form-1"
                       placeholder="Enter name"
                       name="name">
                <p *ngIf="OnformSubmitted && editForm.controls['name'].hasError('required')" class="text-danger">Name
                  is required</p>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group mb-2">
                <label for="horizontal-form-2">Email</label>
                <input type="text" class="form-control square" formControlName="email" id="horizontal-form-2"
                       placeholder="Enter email"
                       name="email">
                <p *ngIf="editForm.controls['email'].hasError('required') && OnformSubmitted" class="text-danger">
                  Email is
                  required</p>
                <p *ngIf="editForm.controls['email'].hasError('email')" class="text-danger">Invalid email</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <div class="form-group mb-2">
                <label>Phone number</label>
                <fieldset>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">{{ countryCode }}</span>
                    </div>
                    <input type="text" formControlName="number" class="form-control" placeholder="Phone number">
                  </div>
                  <p *ngIf="editForm.controls['number'].hasError('pattern')" class="text-danger">Invalid Phone
                    Number</p>
                  <p *ngIf="editForm.controls['number'].hasError('required') && OnformSubmitted" class="text-danger">
                    Phone number is
                    required</p>
                </fieldset>
              </div>
            </div>
            <div class="col-md-12 col-12">
              <div class="form-group mb-2">
                <label>Gender</label>
                <div class="d-flex mt-1">
                  <div class="radio">
                    <input type="radio" name="gender" id="radio1" checked value="male"
                           formControlName="gender"
                           [checked]="this.userData.auth.data.gender == 'male'">
                    <label for="radio1">Male</label>
                  </div>
                  <div class="radio ml-1">
                    <input type="radio" name="gender" id="radio2" value="female"
                           formControlName="gender"
                           [checked]="this.userData.auth.data.gender == 'female'">
                    <label for="radio2">Female</label>
                  </div>
                  <div class="radio ml-1">
                    <input type="radio" name="gender" id="radio3"
                           [checked]="this.userData.auth.data.gender == 'others'"
                           value="others" formControlName="gender">
                    <label for="radio3">Others</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-12">
              <button type="submit" class="btn btn-primary float-right"><i class="ft-check-square mr-1"></i>Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #AutoLogout let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center">Auto Logout Setting</h4>
    <span class="ft ft-x cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')"></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-12 cl-sm-12 col-lg-12">
        <label>Interval (mins)</label>
        <ng-select placeholder="Select Interval" [(ngModel)]="timerLogout" [clearable]="false">
          <ng-option value="30">30 mins</ng-option>
          <ng-option value="60">60 mins</ng-option>
          <ng-option value="90">90 mins</ng-option>
          <ng-option value="Never">Never</ng-option>
        </ng-select>
        <p class="text-danger" *ngIf="submitted && !this.timerLogout">Interval
          is required</p>
      </div>
      <div class="col-md-12 col-12 text-center">
        <button class="btn btn-primary mt-2"
                (click)="setAutoLogoutForUser(modal)"><i class="ft-check-circle mr-1"></i>Save
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner
  template="<img src='assets/img/Pulseright-Logo-Animation-White.gif' width='150' height='150' />"></ngx-spinner>
