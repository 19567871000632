import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  organizationGroup: any;
  currentDate: Date = new Date();

  constructor() {
    if (window.location.pathname !== '/booking') {
      this.organizationGroup = JSON.parse(localStorage.getItem('organizationGroup')).data;
    }
  }
}
